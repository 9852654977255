import React from 'react'
import { SearchProvider } from '../../context/search-provider'
import { useQuery } from 'urql'
import { AddToCart } from '../add-to-cart'
import { StoreContext } from '../../context/store-context'

const ProductQuery = `
query getProduct ($id: ID!) {
    product(id: $id) {
        title
        handle
        variants(first: 50) {
          edges {
            node {
              id
              availableForSale
              title
              price
              sku
              selectedOptions {
                name
                value
              }
            }
          }
        }
        options {
          id
          name
          values
        }
    }
}
`

function Coords({
    productId,
    quantity,
    setCoordsVariant,
    selectedMainVariant,
    mainProductHandle,
    belongsToProductId
}) {
    const productOptionsRef = React.useRef([])
    const { addLineItemsToCart, loading } = React.useContext(StoreContext)

    const [result, reexecuteQuery] = useQuery({
        query: ProductQuery,
        variables: {
            id: productId
        }
    })
    const [options, setOptions] = React.useState([])
    const [variant, setVariant] = React.useState()
    const [variants, setVariants] = React.useState([])
    const [selectedOptions, setSelectedOptions] = React.useState({})

    const { data, fetching, error } = result

    const handleOptionChange = (optionName, value, needOptionUpdate = true) => {
        if (value === "" || variant === undefined) {
          return
        }
    
        const currentOptions = {
            ...selectedOptions,
            [optionName]: value
        }
        setSelectedOptions(currentOptions)
    
        let selectedVariant = data.product.variants.edges.find(({node}) =>
            node.selectedOptions.every(({ name, value }) => value === currentOptions[ name ])
        )?.node
        
        if (!!!selectedVariant) {
          selectedVariant = {
            ...data.product.variants.edges[0].node
          }
        }
    
        setVariant({
            ...selectedVariant,
            storeFrontId: window.btoa(selectedVariant.id)
        })

        if (needOptionUpdate) updateOptions()
    }

    const updateOptions = () => {
        const selectedPreviousOptions = []
    
        if(options.length <= 1) return true
    
        for(let i = 0; i < productOptionsRef.current.length; i++) {
          // get available options
            const selectBox = productOptionsRef.current[i].current

            let selectedOption = i !== 0 ? productOptionsRef.current[i - 1]?.current?.value : null
        
            if (i !== 0) {
                selectedOption = selectedOption ? selectedOption : productOptionsRef.current[i - 1]?.current?.options[0].value
                selectedPreviousOptions.push(selectedOption)
            }
        
            let availableOptions = []
            const availableOptionsIndexs = []
            const unAvailableOptionsIndexs = []

            const variantSelectedOptions = variants
            .filter(variant => variant.availableForSale) // hide zero stock variants
            .map(variant => variant.title)
        
            availableOptions = variantSelectedOptions.filter(variantSelectedOption => {
                let available = true
        
                selectedPreviousOptions.forEach((val, index) => {
                    if(val !== variantSelectedOption.split("/")[index].trim()) {
                        available = false
                    }
                })
        
                return available
            })
            .map(availableOption => availableOption.split('/')[i].trim())
        
            availableOptions = [...new Set(availableOptions)] // remove duplicated items
        
            productOptionsRef.current[i]?.current?.childNodes.forEach(child => {
                // replace span to option to reset hidden options
                if(child.tagName.toLowerCase() === 'span' ) {
                    let newOption = document.createElement('option')
                    newOption.innerHTML = child.innerHTML
                    newOption.value = child.dataset.value
                    child.parentNode.replaceChild(newOption, child)
                }
            })
        
            Array.from(selectBox?.options || []).forEach((option, index) => {
                option.removeAttribute('selected') // reset selected options
        
                if(!availableOptions.includes(option.value.trim())) {
                    unAvailableOptionsIndexs.push(index)
                }
                else {
                    availableOptionsIndexs.push(index)
                }
            })
        
            // hide the  unavailable option by replacing option to span
            unAvailableOptionsIndexs.forEach(val => {
                const span = document.createElement('span');
                span.innerHTML = selectBox.childNodes[val].innerHTML;
                span.dataset.value = selectBox.childNodes[val].value;
                selectBox.replaceChild(span, selectBox.childNodes[val]);
            })
        
            // change default selected if it is unavailable variant
            const optionName = selectBox?.id
            if(!availableOptions.includes(selectedOptions[optionName]) && optionName) {
                const currentOptions = {
                    ...selectedOptions,
                    [optionName]: availableOptions[0]
                }
                // setSelectedOptions(currentOptions)
        
                let selectedVariant = variants.find((variant) =>
                    variant.selectedOptions.every(({ name, value }) => value === currentOptions[ name ])
                )
        
                if (!!!selectedVariant) {
                    selectedVariant = {
                    ...variant
                    }
                }
            
                setVariant({
                    ...selectedVariant,
                    storeFrontId: window.btoa(selectedVariant.id)
                })
                selectBox.options[availableOptionsIndexs[0]]?.setAttribute('selected', true)
            }
        }
    }

    const addToCart = () => {
        const lineItemsToUpdate = [
            {
                variantId: variant.storeFrontId,
                handle: data.product.handle,
                quantity: parseInt(quantity, 10),
                belongsToProductId: belongsToProductId,
                isCoords: true
            },
            {
                variantId: selectedMainVariant.storefrontId,
                handle: mainProductHandle,
                quantity: parseInt(quantity, 10),
                belongsToProductId: productId,
                isCoords: false
            }
        ]
        addLineItemsToCart(lineItemsToUpdate)
    }

    React.useEffect(() => {
        if (data?.product) {
            setVariant({
                ...data.product.variants.edges[0].node,
                storeFrontId: window.btoa(data.product.variants.edges[0].node.id)
            })
            setOptions(data.product.options)
            setSelectedOptions(
                data.product.variants.edges[0].node.selectedOptions.reduce((options, { name, value }) => ({ [ name ]: value, ...options }), {})
            )
            setVariants(
                data.product.variants.edges.map(({node}) => node)
            )
            setCoordsVariant({
                ...data.product.variants.edges.map(({node}) => node),
                handle: data.product.handle
            })
        }
    }, [data])

    React.useEffect(() => {
        if (options.length > 0) {
            console.log('[options]', options)
            productOptionsRef.current = options.map((option, i) => productOptionsRef.current[i] ?? React.createRef())
        }
    }, [options])

    React.useEffect(() => {
        if (selectedOptions && productOptionsRef.current) {
            updateOptions()
        }
    }, [selectedOptions, productOptionsRef.current])

    React.useEffect(() => {
        setTimeout(() => {
            updateOptions()
        }, 500)
    }, [])

    React.useEffect(() => {
        if (data?.product) {
            setCoordsVariant({
                ...variant,
                handle: data.product.handle
            })
        }
    }, [variant, data])

    return (
        <React.Fragment>
                {
                    fetching ? (
                        <div className={`w-full flex items-center justify-center z-50 ${productId ? 'h-64' : 'hidden'}`}>
                            <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-primary border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                        </div>
                    ) : (
                        variant && (
                            <div className='py-2'>
                                <div>
                                    <h2 className={`text-base sm:text-sxl font-bold pb-1 leading-5`}>{data.product.title}</h2>
                                </div>
                                <div className="py-2 sm:mt-2">
                                    <fieldset className={``}>
                                    {data.product.variants.edges.length > 0 && options.length > 0 &&
                                        data.product.options.map(({ id, name, values }, index) => {
                                        return (
                                            <div className={``} key={id}>
                                                <select
                                                    className={`border border-gray-xs rounded-none w-full py-3 font-semibold px-2 h-12 ${index !== 0 && 'mt-2'}`}
                                                    aria-label="Variants"
                                                    value={selectedOptions[name]}
                                                    onChange={(event) => handleOptionChange(name, event.target.value)}
                                                    ref={productOptionsRef.current[index]}
                                                    id={name}
                                                >
                                                    {values.map((value) => (
                                                    <option value={value} key={`${name}-${value}`}>
                                                        {value}
                                                    </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )
                                        })}
                                    </fieldset>
                                </div>

                                <div className="py-2">
                                    <button
                                        type="submit"
                                        className={`bg-primary text-white uppercase w-full py-3 px-4`}
                                        onClick={addToCart}
                                        disabled={!variant.availableForSale || loading}
                                    >
                                        {variant.availableForSale ? "Add to Bag" : "Out of Stock"}
                                    </button>
                                </div>
                            </div>
                        )
                    )
                }
        </React.Fragment>
    )
}

export default function CoordsTemplate(props) {
    return (
        <SearchProvider>
            <Coords {...props} />
        </SearchProvider>
    )
}