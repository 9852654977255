import * as React from "react"
import { graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../context/store-context"
import { AddToCart } from "../components/add-to-cart"
import { formatPrice } from "../utils/format-price"
import { Seo } from "../components/seo/product-page"
import { FavoriteAltIcon, FavoritedIcon, ShareIcon } from "../icons"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { updateURLParameter } from '../utils/helpers'
import { Layout } from "../components/layout/layout"
import { urlBuilder } from "../utils/helpers"
import { TagInfo } from "../components/product"
import Coords from "../components/product/co-ords"

const appUrl = process.env.GATSBY_APP_URL

export default function Product({ data: { product, allProduct } }) {
  const {
    shopifyId,
    handle,
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    media,
    media: [firstImage],
    metafields
  } = product

  const location = useLocation()

  const subTitle = metafields.find(metafield => metafield.key === 'subtitle')?.value || ''
  const shippingText = (metafields.find(metafield => metafield.key === 'shipping')?.value || '').replace('"', '').replace('[', '').replace(']', '').replace('"', '')
  const sterilisedText = (metafields.find(metafield => metafield.key === 'sterilised')?.value || '').replace('"', '').replace('[', '').replace(']', '').replace('"', '')
  const coordsProductId = (metafields.find(metafield => metafield.key === 'add_a_bar')?.value || '').replace('"', '').replace('[', '').replace(']', '').replace('"', '')

  const { client, favorites, recentProducts, addProductToRecentView, toggleFavorite, resetFavorites } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [selectedOptions, setSelectedOptions] = React.useState(
    initialVariant.selectedOptions.reduce((options, { name, value }) => ({ [ name ]: value, ...options }), {})
  )
  const [quantity, setQuantity] = React.useState(1)
  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedCarouselNumber, setSelectedCarouselNumber] = React.useState(0)
  const [filteredFavorites, setFavorites] = React.useState([])
  const [filteredRecentProducts, setRecentProducts] = React.useState([])
  const [structuredData, setStructuredData] = React.useState({})
  const [coordsVariant, setCoordsVariant] = React.useState(undefined)
  const [price, setPrice] = React.useState(formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  ))
  const [comparedAtPrice, setComparedAtPrice] = React.useState(formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.compareAtPrice
  ))

  const carousel = React.useRef()
  const thumbs = React.useRef()
  const favoritesRef = React.useRef()
  const productDetails = React.useRef()
  const productOptionsRef = React.useRef([])
  productOptionsRef.current = options.map((option, i) => productOptionsRef.current[i] ?? React.createRef());

  const productVariant = client.product.helpers.variantForOptions(product, variant) || variant

  const variantSelectedOptions = variants
  .filter(variant => variant.availableForSale) // hide zero stock variants
  .map(variant => variant.title)

  const [available, setAvailable] = React.useState(productVariant.availableForSale)

  const hasVariants = variants.length > 1
  const hasImages = media.length > 0

  const checkAvailablity = React.useCallback(
    (productId) => {
      setIsLoading(true)
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.shopifyId
          ) ?? []
        
        setIsLoading(false)

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.shopifyId, client.product]
  )

  const handleOptionChange = (optionName, value, needOptionUpdate = true) => {
    if (value === "") {
      return
    }

    const currentOptions = {
      ...selectedOptions,
      [optionName]: value
    }
    setSelectedOptions(currentOptions)

    let selectedVariant = variants.find((variant) =>
      variant.selectedOptions.every(({ name, value }) => value === currentOptions[ name ])
    )
    
    if (!!!selectedVariant) {
      selectedVariant = {
        ...initialVariant
      }
    }

    window.history.replaceState('', '', updateURLParameter(window.location.href, "variant_id", selectedVariant.shopifyId.split('/').reverse()[0]))
    moveCarousel(selectedVariant)

    setPrice(formatPrice(
      priceRangeV2.minVariantPrice.currencyCode,
      selectedVariant.price
    ))
    setComparedAtPrice(formatPrice(
      priceRangeV2.minVariantPrice.currencyCode,
      selectedVariant.compareAtPrice
    ))

    setVariant({ ...selectedVariant })

    if (needOptionUpdate) updateOptions()
  }

  const updateOptions = () => {
    const selectedPreviousOptions = []

    if(options.length <= 1) return true

    for(let i = 1; i < productOptionsRef.current.length; i++) {
      // get available options
      const selectBox = productOptionsRef.current[i].current
      let selectedOption = productOptionsRef.current[i - 1]?.current?.value

      selectedOption = selectedOption ? selectedOption : productOptionsRef.current[i - 1]?.current?.options[0].value
      selectedPreviousOptions.push(selectedOption)

      let availableOptions = []
      const availableOptionsIndexs = []
      const unAvailableOptionsIndexs = []

      availableOptions = variantSelectedOptions.filter(variantSelectedOption => {
        let available = true

        selectedPreviousOptions.forEach((val, index) => {
          if(val !== variantSelectedOption.split("/")[index].trim()) {
            available = false
          }
        })

        return available
      })
      .map(availableOption => availableOption.split('/')[i].trim())

      availableOptions = [...new Set(availableOptions)] // remove duplicated items

      productOptionsRef.current[i]?.current.childNodes.forEach(child => {
        // replace span to option to reset hidden options
        if(child.tagName.toLowerCase() === 'span' ) {
          let newOption = document.createElement('option')
          newOption.innerHTML = child.innerHTML
          newOption.value = child.dataset.value
          child.parentNode.replaceChild(newOption, child)
        }
      })

      Array.from(selectBox.options).forEach((option, index) => {
        option.removeAttribute('selected') // reset selected options

        if(!availableOptions.includes(option.value.trim())) {
          unAvailableOptionsIndexs.push(index)
        }
        else {
          availableOptionsIndexs.push(index)
        }
      })

      // hide the  unavailable option by replacing option to span
      unAvailableOptionsIndexs.forEach(val => {
        const span = document.createElement('span');
        span.innerHTML = selectBox.childNodes[val].innerHTML;
        span.dataset.value = selectBox.childNodes[val].value;
        selectBox.replaceChild(span, selectBox.childNodes[val]);
      })

      // change default selected if it is unavailable variant
      const optionName = selectBox.id
      if(!availableOptions.includes(selectedOptions[optionName])) {
          const currentOptions = {
            ...selectedOptions,
            [optionName]: availableOptions[0]
          }
          // setSelectedOptions(currentOptions)
  
          let selectedVariant = variants.find((variant) =>
            variant.selectedOptions.every(({ name, value }) => value === currentOptions[ name ])
          )

          if (!!!selectedVariant) {
            selectedVariant = {
              ...initialVariant
            }
          }
      
          window.history.replaceState('', '', updateURLParameter(window.location.href, "variant_id", selectedVariant.shopifyId.split('/').reverse()[0]))
          moveCarousel(selectedVariant)
      
          setVariant({ ...selectedVariant })
          selectBox.options[availableOptionsIndexs[0]].setAttribute('selected', true)

      }
    }
  }

  const moveCarousel = (selectedVariant) => {
    const imageId = selectedVariant?.media[0]?.shopifyId
    const imageElem = document.querySelector('[data-image-id="' + imageId + '"]')
    if (imageElem) {
      const carouselIndex = imageElem.dataset.carouselIndex
      setSelectedCarouselNumber(parseInt(carouselIndex))
    }
  }

  const handleSlideChange = (index) => {
    const imageElem = document.querySelector('[data-carousel-index="' + index + '"]')
    setSelectedCarouselNumber(parseInt(index))
    updateOptions()

    if (imageElem) {
      const imageId = imageElem.dataset.imageId
      const selectedVariant = variants.find((variant) => {
        return isEqual(variant?.media[0]?.shopifyId, imageId)
      })

      if (selectedVariant) {
        window.history.replaceState('', '', updateURLParameter(window.location.href, "variant_id", selectedVariant.shopifyId.split('/').reverse()[0]));
        setVariant({...selectedVariant})
      }

      const activeThumbnail = document.querySelector('.active-thumbnail')
      activeThumbnail.parentNode.scrollTop = activeThumbnail.offsetTop;
    }
  }

  const init = React.useCallback(() => {
    setQuantity(1)
    addProductToRecentView(title, handle, getSrc(firstImage?.image?.gatsbyImageData))

    const queryString = location.search
    if (queryString) {
      const variantId = "gid://shopify/ProductVariant/" + queryString.replace('?', '').split('=')[1]
      const selectedVariant = variants.find((variant) => {
        return isEqual(variant.shopifyId, variantId)
      })
      setVariant({ ...selectedVariant })
      setSelectedOptions(selectedVariant.selectedOptions.reduce((options, { name, value }) => ({ [ name ]: value, ...options }), {}))
      moveCarousel(selectedVariant)
    }
    else {
      setSelectedOptions(variant.selectedOptions.reduce((options, { name, value }) => ({ [ name ]: value, ...options }), {}))
    }
    
  }, [title, handle, firstImage, location, variants])

  const shareNative = (url) => {
    if (navigator.share) {
      navigator.share({
        url: url
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }
  }

  const updateCurrentSlide = (index) => {
    setSelectedCarouselNumber(parseInt(index))
  }

  React.useEffect(() => {
    init()
  }, [init])

  React.useEffect(() => {
    updateOptions()
  }, [selectedOptions])

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.shopifyId, checkAvailablity, product.storefrontId])

  React.useEffect(() => {
    if (favorites.length && allProduct?.nodes.length) {
      const tempFavorites = []
      favorites.forEach((favorite, index) => {
        allProduct.nodes.forEach((prod) => {
          if (favorite.handle === prod.handle) tempFavorites.push({ ...favorite, index })
        })
      })

      setFavorites(tempFavorites
        .sort((a, b) => {
          return a.index - b.index
        })
        .reverse()
      )

      if (favorites.length !== tempFavorites.length) {
        resetFavorites(tempFavorites)
      }
    }
  }, [favorites, allProduct])

  React.useEffect(() => {
    if (recentProducts.length && allProduct?.nodes.length) {
      const tempRecentProducts = []
      recentProducts.forEach((recentProd, index) => {
        allProduct.nodes.forEach((prod) => {
          if (recentProd.handle === prod.handle) tempRecentProducts.push({ ...recentProd, index })
        })
      })
      setRecentProducts(tempRecentProducts
        .sort((a, b) => {
          return a.index - b.index
        })
        .slice(0, tempRecentProducts.length - 1)
        .reverse()
      )
    }
  }, [recentProducts, allProduct])

  React.useEffect(() => {
    const offers = []

    variants.forEach(variant => {
      let offer = {
        "@type": "Offer",
        "sku": variant.sku,
        "availability": `http://schema.org/${variant.availableForSale ? 'InStock' : 'OutOfStock'}`,
        "price": variant.price,
        "priceCurrency": "GBP",
        "url": `${appUrl}/${handle}?variant_id=${variant.shopifyId.split('/').reverse()[0]}`
      }
      offers.push(offer);
    })

    const jsonLdTemp = {
      "@context": "http://schema.org",
      "@type": "Product",
      "@id": shopifyId,
      "name": title,
      "url": `${appUrl}/${handle}`,
      "image": getSrc(firstImage?.image?.gatsbyImageData),
      "description": description,
      "sku": variant?.sku,
      "brand": {
        "@type": "Thing",
        "name": "PierceOfArt"
      },
      "offers": offers
    }

    setStructuredData(jsonLdTemp)
  }, [variant, shopifyId, title, description, handle, firstImage, variants])

  React.useEffect(() => {
    setTimeout(() => {
      const carouselHeight = carousel.current?.listRef?.clientHeight || 0
      const productDetailsHeight = productDetails.current?.clientHeight || 0
      const thumbsHeight = thumbs.current?.clientHeight || 0
      const favoritesHeight = favoritesRef.current?.clientHeight || 0

      if (thumbsHeight > carouselHeight) thumbs.current.style.height = carouselHeight + 'px'
      if (favoritesHeight > productDetailsHeight && productDetailsHeight > carouselHeight) favoritesRef.current.style.height = productDetailsHeight + 'px'
      else if (favoritesHeight > productDetailsHeight && productDetailsHeight < carouselHeight) favoritesRef.current.style.height = carouselHeight + 'px'
    }, 500)
  }, [carousel])

  React.useEffect(() => {
    if (filteredRecentProducts.length > 0) {
      setTimeout(() => {
        let imageHeight = 1
        const recentProductImages = document.querySelectorAll('.recent-product-image')
        Array.from(recentProductImages).forEach(elem => {
          if (elem.clientHeight > imageHeight) imageHeight = elem.clientHeight
        })
    
        if (imageHeight > 1 && imageHeight < 500) {
          Array.from(recentProductImages).forEach(elem => {
            elem.parentNode.style.height = imageHeight + 'px'
          })
        }
      }, 500)
    }
  }, [filteredRecentProducts])

  React.useEffect(() => {
    if (filteredFavorites.length > 0) {
      setTimeout(() => {
        let imageHeight = 1
        const favoriteProductImages = document.querySelectorAll('.favorite-product-image')
        Array.from(favoriteProductImages).forEach(elem => {
          if (elem.clientHeight > imageHeight) imageHeight = elem.clientHeight
        })
    
        if (imageHeight > 1 && imageHeight < 500) {
          Array.from(favoriteProductImages).forEach(elem => {
            elem.parentNode.style.height = imageHeight + 'px'
          })
        }
      }, 500)
    }
  }, [filteredFavorites])

  return (
    <Layout>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          price={price.replace('£', '')}
          currency={'GBP'}
          image={urlBuilder({baseUrl: firstImage?.image?.originalSrc, width: 400, height: 250, format: 'jpg', cropPosition: 'bottom'})}
          structuredData={structuredData}
        />
      ) : undefined}
      <div className={`mx-auto max-w-screen-xl sm:py-4`}>
        <div className={`sm:grid sm:grid-cols-2 sm:max-h-screen overflow-hidden`}>
          <div className="sm:py-4 sm:px-3">
            <div className="relative pb-2">
              {hasImages && (
                <div className={`relative`}>
                  <div
                    role="group"
                    aria-label="gallery"
                    aria-describedby="instructions"
                    className="flex"
                  >
                    <div className="thumbs-wraper hidden sm:grid sm:grid-col-1 sm:gap-1 sm:mr-1 overflow-y-auto overflow-x-hidden shrink-0 h-fit custom-scroll" ref={thumbs}>
                      {
                        media.map((mediaItem, index) => (
                          <div
                            key={`thumbs-image-${mediaItem.id}`}
                            className={`flex whitespace-nowrap w-32 h-fit cursor-pointer ${selectedCarouselNumber === index && 'border-2 border-red-primary active-thumbnail'}`}
                            data-image-id={mediaItem.shopifyId}
                            data-carousel-index={index}
                            onClick={() => handleSlideChange(index)}
                          >
                            <div className="w-full h-product-image flex items-center justify-center">
                              <div className="w-full">
                                <GatsbyImage
                                  objectFit="cover"
                                  loading={index === 0 ? "eager" : "lazy"}
                                  alt={
                                    mediaItem.alt
                                      ? mediaItem.alt
                                      : `Product Image of ${title} #${index + 1}`
                                  }
                                  image={mediaItem?.image?.gatsbyImageData}
                                  width={100}
                                  // onLoad={() => handleBackground(index)}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className={`flex overflow-x-auto`}>
                      <Carousel
                        showThumbs={false}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                        infiniteLoop={true}
                        selectedItem={selectedCarouselNumber}
                        onChange={handleSlideChange}
                        swipeable={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        ref={carousel}
                      >
                      {
                        media.map((mediaItem, index) => (
                          <div
                            key={`product-image-${mediaItem.id}`}
                            className={`flex flex-100 whitespace-nowrap`}
                            data-image-id={mediaItem.shopifyId}
                            data-carousel-index={index}
                          >
                            <div className="w-full h-product-image flex items-center justify-center">
                              <div className="w-full">
                                <GatsbyImage
                                  objectFit="contain"
                                  loading={index === 0 ? "eager" : "lazy"}
                                  alt={
                                    mediaItem.alt
                                      ? mediaItem.alt
                                      : `Product Image of ${title} #${index + 1}`
                                  }
                                  image={mediaItem?.image?.gatsbyImageData}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </Carousel>
                    </div>
                  </div>
                </div>
              )}
              {!hasImages && (
                <div className="relative">
                  <div className="w-0 h-0" ref={thumbs}></div>
                  <span className={`flex items-center justify-center min-h-300`} ref={carousel}>No preview image</span>
                </div>
              )}
              {
                (shippingText || sterilisedText) && (
                  <div className="block sm:hidden">
                    <TagInfo shippingText={shippingText} sterilisedText={sterilisedText} />
                  </div>
                )
              }
            </div>
            <div className="arrows-wrapper sm:hidden">
              {
                media.length > 1 && (
                  <div className="flex items-center justify-center pt-2">
                    <ul className="flex items-center justify-center">
                    {
                      media.map((mediaItem, index) => (
                        <li
                          className={`w-3 h-3 rounded-full mx-1 border border-red-primary cursor-pointer ${selectedCarouselNumber === index && 'bg-red-primary'}`}
                          key={mediaItem.id}
                          onClick={() => updateCurrentSlide(index)}
                          role='presentation'
                        ></li>
                      ))
                    }
                    </ul>
                  </div>
                )
              }
            </div>
          </div>

          <div className="py-4 px-3 block sm:flex">
            <div className="product-details sm:flex-grow h-fit" ref={productDetails}>
              <h1 className={`text-base sm:text-sxl font-bold pb-1 leading-5`}>{title}</h1>
              <h3 className={`text-sm text-gray-xl font-semibold pb-1`}>{subTitle}</h3>

              <div className="flex items-center justify-between">
                <h2 className={`font-bold sm:py-2 hidden`}>
                  <span className={`${comparedAtPrice && variant.compareAtPrice > 0  ? 'text-red' : 'text-var-gray90'}`}>{price}</span> {
                    comparedAtPrice && variant.compareAtPrice > 0 ? <s className="ml-1 font-semibold">{comparedAtPrice}</s> : null
                  }
                </h2>
                <div className="sm:hidden">
                  <button className="p-1" onClick={() => toggleFavorite(title, handle, getSrc(firstImage?.image?.gatsbyImageData))}>
                    { favorites.find(product => product.handle === handle) ? <FavoritedIcon fill={'#ED0C3F'} /> : <FavoriteAltIcon fill={'#ED0C3F'} /> }
                  </button>
                  <button className="p-1" onClick={() => shareNative(`${appUrl}/${handle}`)}>
                    <ShareIcon fill={'#000000'} />
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                {
                  (shippingText || sterilisedText) && (
                    <div className="hidden sm:block flex-grow mr-4">
                      <TagInfo shippingText={shippingText} sterilisedText={sterilisedText} />
                    </div>
                  )
                }
                <div className="hidden w-20 shrink-0 sm:flex items-center justify-between">
                  <button className="p-1" onClick={() => toggleFavorite(title, handle, getSrc(firstImage?.image?.gatsbyImageData))}>
                    { favorites.find(product => product.handle === handle) ? <FavoritedIcon width={30} height={30} fill={'#ED0C3F'} /> : <FavoriteAltIcon width={30} height={30} fill={'#ED0C3F'} /> }
                  </button>
                  <button className="p-1" onClick={() => shareNative(`${appUrl}/${handle}`)}>
                    <ShareIcon width={30} height={30} fill={'#000000'} />
                  </button>
                </div>
              </div>

              <div className="py-2 sm:mt-2">
                <fieldset className={``}>
                  {hasVariants &&
                    options.map(({ shopifyId, name, values }, index) => {
                      return (
                        <div className={``} key={shopifyId}>
                          <select
                            className={`border border-gray-xs rounded-none w-full py-3 font-semibold px-2 h-12 ${index !== 0 && 'mt-2'}`}
                            aria-label="Variants"
                            onChange={(event) => handleOptionChange(name, event.target.value)}
                            value={selectedOptions[name]}
                            ref={productOptionsRef.current[index]}
                            id={name}
                          >
                            {values.map((value) => (
                              <option value={value} key={`${name}-${value}`}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      )
                    })}
                </fieldset>
              </div>

              {
                coordsProductId ? (
                  <div className="py-2">
                    <Coords
                      productId={coordsProductId}
                      quantity={quantity}
                      setCoordsVariant={setCoordsVariant}
                      selectedMainVariant={productVariant}
                      mainProductHandle={product.handle}
                      belongsToProductId={product.shopifyId}
                    />
                  </div>
                ) : (
                  <div className={`py-2`}>
                    <AddToCart
                      variantId={productVariant.storefrontId}
                      handle={product.handle}
                      quantity={quantity}
                      available={available}
                      isLoading={isLoading}
                      // coordsVariant={coordsVariant} // disable default co-ords
                    />
                  </div>
                )
              }

              {
                filteredRecentProducts.length > 1 && (
                  <div className="py-2 flex items-center whitespace-nowrap overflow-x-auto sm:hidden">
                    {
                      filteredRecentProducts.map((item, index) => (
                        <div key={index}>
                          <Link to={`/${item.handle}`} className="flex-100 mr-1 block">
                            <div className="w-40">
                              <img src={item.image} alt={item.title} className="overflow-hidden whitespace-normal recent-product-image w-full h-full object-cover" />
                            </div>
                          </Link>
                        </div>
                      ))
                    }
                  </div>
                )
              }

              <div className={`text-sm py-3 product-description`} dangerouslySetInnerHTML={{__html: descriptionHtml}}></div>
            </div>

            {
              filteredFavorites.length > 0 && (
                <div className="favorites-wrapper grid grid-cols-2 sm:grid sm:grid-cols-1 gap-1 shrink-0 sm:ml-4 overflow-y-auto overflow-x-hidden h-fit custom-scroll" ref={favoritesRef}>
                  {
                    filteredFavorites.map((item, index) => (
                      <div key={index}>
                        <Link to={`/${item.handle}`} className="h-full w-full sm:w-32 block">
                          <div className="w-full h-full flex items-center justify-center">
                            <img src={`${item.image}`} alt={item.title} className="w-full overflow-hidden whitespace-normal favorite-product-image h-full object-cover" />
                          </div>
                        </Link>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        </div>
        {
          filteredRecentProducts.length > 1 && (
            <div className="hidden py-2 sm:flex items-start whitespace-nowrap overflow-x-auto mx-3">
              {
                filteredRecentProducts.map((item, index) => (
                  <div key={index}>
                    <Link to={`/${item.handle}`} className="flex-100 mr-1 block">
                      <div className="w-40">
                        <img src={item.image} alt={item.title} className="overflow-hidden whitespace-normal recent-product-image w-full h-full object-cover" />
                      </div>
                    </Link>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      shopifyId
      title
      handle
      description
      descriptionHtml
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      media {
        ... on ShopifyMediaImage {
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 640, placeholder: BLURRED)
            originalSrc
          }
          id
          shopifyId
          alt
        }
      }
      variants {
        shopifyId
        availableForSale
        storefrontId
        title
        price
        compareAtPrice
        sku
        selectedOptions {
          name
          value
        }
        media {
          shopifyId
        }
      }
      options {
        name
        values
        shopifyId
      }
      metafields {
        id
        key
        namespace
        value
      }
    }
    allProduct: allShopifyProduct {
      nodes {
        handle
      }
    }
  }
`
