import * as React from "react"
import { StoreContext } from "../context/store-context"

export function AddToCart({
  variantId,
  handle,
  quantity,
  available,
  coordsVariant = undefined,
  trigger = false,
  isLoading,
  ...props
}) {
  const { checkout, triggerAddCoords, addVariantToCart, addLineItemsToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    
    if (coordsVariant !== undefined) {
      let isCoordsVariantAdded = false
      checkout.lineItems.forEach(lineItem => {
        const lineItemVariantId = lineItem.variant.id
        if (lineItemVariantId === coordsVariant.id) isCoordsVariantAdded = true
      })
      console.log('[coordsVariant]', coordsVariant)

      if (!isCoordsVariantAdded) {
        const lineItemsToUpdate = [
          {
            variantId,
            handle: handle,
            quantity: parseInt(quantity, 10),
          },
          {
            variantId: coordsVariant.storeFrontId,
            handle: coordsVariant.handle,
            quantity: parseInt(1, 10)
          }
        ]
        addLineItemsToCart(lineItemsToUpdate)
      }
      else {
        addVariantToCart(variantId, handle, quantity)
      }
    }
    else {
      addVariantToCart(variantId, handle, quantity)
    }
  }

  return (
    <button
      type="submit"
      className={`bg-primary text-white uppercase w-full py-3 px-4 ${!available || loading || isLoading ? 'cursor-not-allowed opacity-50' : ''}`}
      onClick={addToCart}
      disabled={!available || loading || isLoading}
      {...props}
    >
      {available ? "Add to Bag" : "Out of Stock"}
    </button>
  )
}
